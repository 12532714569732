export const environment = {
  production: true,
  DDGId: 1,
  apiUrl: 'https://payslipapi.azurewebsites.net/api',
  baseEmployeeEndpoint: 'https://uat-employee.azurewebsites.net/api/',
  baseAdminEndpoint: 'https://uat-clientadmin.azurewebsites.net/api/',
  baseIdentityManagementEndpoint: 'https://uat-identitymanagement.azurewebsites.net/api/',
  basePayManagerEndpoint: 'https://uat-paymanager.azurewebsites.net/api/',
  baseHelpdeskEndpoint: 'https://uat-helpdesk.azurewebsites.net/api/',
  baseSharedEndpoint: 'https://uat-shared.azurewebsites.net/api/',
  blockchainEndpoint: 'http://wcsregantoledger.uksouth.cloudapp.azure.com:3000/api/',
  defaultImageURL: '../../../assets/img/default-avatar.png',
  name: 'uat'
};
